<template>
  <div class="side_menu">
    <!-- {/* logo */} -->
    <div class="signup_body_text_logo">
      <div class="signup_body_text_logo_inner">
        <img src="../../assets/logo.png" alt="logo" />
      </div>
    </div>

    <div class="side_menu_links">
      <router-link to="/">
        <svg
          width="18"
          height="18"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="6" height="9" fill="#766EA9" />
          <rect x="8" width="7" height="5" fill="#766EA9" />
          <rect x="8" y="7" width="7" height="8" fill="#766EA9" />
          <rect y="11" width="6" height="4" fill="#766EA9" />
        </svg>

        <p>Dashboard</p>
      </router-link>

      <router-link to="/shipments">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_1_2866)">
            <path
              d="M1.94584 9.31504C1.42384 9.14104 1.41884 8.86004 1.95584 8.68104L21.0428 2.31904C21.5718 2.14304 21.8748 2.43904 21.7268 2.95704L16.2728 22.043C16.1228 22.572 15.8178 22.59 15.5938 22.088L11.9998 14L17.9998 6.00004L9.99984 12L1.94584 9.31504Z"
              fill="#554B95"
            />
          </g>
          <defs>
            <clipPath id="clip0_1_2866">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>

        <p>Shipments</p>
      </router-link>

      <router-link to="/procurement">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_1_2599)">
            <path
              d="M16.757 3.00001L9.291 10.466L9.299 14.713L13.537 14.706L21 7.24301V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4.00001C3 3.73479 3.10536 3.48044 3.29289 3.2929C3.48043 3.10536 3.73478 3.00001 4 3.00001H16.757ZM20.485 2.10001L21.9 3.51601L12.708 12.708L11.296 12.711L11.294 11.294L20.485 2.10001Z"
              fill="#554B95"
            />
          </g>
          <defs>
            <clipPath id="clip0_1_2599">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>

        <p>Procurement</p>
      </router-link>

      <router-link to="/supplier-payments">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_1_2860)">
            <path
              d="M11 2L18.298 4.28C18.5015 4.34354 18.6794 4.47048 18.8057 4.64229C18.932 4.81409 19.0001 5.02177 19 5.235V7H21C21.2652 7 21.5196 7.10536 21.7071 7.29289C21.8946 7.48043 22 7.73478 22 8V10H9V8C9 7.73478 9.10536 7.48043 9.29289 7.29289C9.48043 7.10536 9.73478 7 10 7H17V5.97L11 4.094L5 5.97V13.374C4.99986 13.9862 5.14025 14.5903 5.41036 15.1397C5.68048 15.6892 6.07311 16.1692 6.558 16.543L6.747 16.679L11 19.58L14.782 17H10C9.73478 17 9.48043 16.8946 9.29289 16.7071C9.10536 16.5196 9 16.2652 9 16V12H22V16C22 16.2652 21.8946 16.5196 21.7071 16.7071C21.5196 16.8946 21.2652 17 21 17L17.78 17.001C17.393 17.511 16.923 17.961 16.38 18.331L11 22L5.62 18.332C4.81252 17.7815 4.15175 17.042 3.69514 16.1779C3.23853 15.3138 2.9999 14.3513 3 13.374V5.235C3.00012 5.02194 3.06829 4.81449 3.19456 4.64289C3.32084 4.47128 3.49862 4.34449 3.702 4.281L11 2Z"
              fill="#554B95"
            />
          </g>
          <defs>
            <clipPath id="clip0_1_2860">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>

        <p>Supplier Payments</p>
      </router-link>

      <!-- <router-link to="/gift-cards">
        <svg
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.3333 9.83334H2.66666V5.83334H6.05333L4.66666 7.72001L5.74666 8.50001L7.33333 6.34001L8 5.43334L8.66666 6.34001L10.2533 8.50001L11.3333 7.72001L9.94666 5.83334H13.3333M13.3333 13.1667H2.66666V11.8333H13.3333M6 3.16668C6.17681 3.16668 6.34637 3.23691 6.4714 3.36194C6.59642 3.48696 6.66666 3.65653 6.66666 3.83334C6.66666 4.01015 6.59642 4.17972 6.4714 4.30475C6.34637 4.42977 6.17681 4.50001 6 4.50001C5.82318 4.50001 5.65361 4.42977 5.52859 4.30475C5.40357 4.17972 5.33333 4.01015 5.33333 3.83334C5.33333 3.65653 5.40357 3.48696 5.52859 3.36194C5.65361 3.23691 5.82318 3.16668 6 3.16668ZM10 3.16668C10.1768 3.16668 10.3464 3.23691 10.4714 3.36194C10.5964 3.48696 10.6667 3.65653 10.6667 3.83334C10.6667 4.01015 10.5964 4.17972 10.4714 4.30475C10.3464 4.42977 10.1768 4.50001 10 4.50001C9.82318 4.50001 9.65361 4.42977 9.52859 4.30475C9.40357 4.17972 9.33333 4.01015 9.33333 3.83334C9.33333 3.65653 9.40357 3.48696 9.52859 3.36194C9.65361 3.23691 9.82318 3.16668 10 3.16668ZM13.3333 4.50001H11.88C11.9533 4.29334 12 4.06668 12 3.83334C12 3.30291 11.7893 2.7942 11.4142 2.41913C11.0391 2.04406 10.5304 1.83334 10 1.83334C9.29999 1.83334 8.69333 2.19334 8.33333 2.73334L8 3.16668L7.66666 2.72668C7.30666 2.19334 6.7 1.83334 6 1.83334C5.46956 1.83334 4.96085 2.04406 4.58578 2.41913C4.21071 2.7942 3.99999 3.30291 3.99999 3.83334C3.99999 4.06668 4.04666 4.29334 4.11999 4.50001H2.66666C1.92666 4.50001 1.33333 5.09334 1.33333 5.83334V13.1667C1.33333 13.9067 1.92666 14.5 2.66666 14.5H13.3333C14.0733 14.5 14.6667 13.9067 14.6667 13.1667V5.83334C14.6667 5.09334 14.0733 4.50001 13.3333 4.50001Z"
            fill="#554B95"
          />
        </svg>

        <p>Gift Cards</p>
      </router-link> -->

      <router-link to="/resources">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_1_2869)">
            <path
              d="M8.68637 4.00001L11.2934 1.39301C11.4809 1.20554 11.7352 1.10022 12.0004 1.10022C12.2655 1.10022 12.5198 1.20554 12.7074 1.39301L15.3144 4.00001H19.0004C19.2656 4.00001 19.5199 4.10536 19.7075 4.2929C19.895 4.48044 20.0004 4.73479 20.0004 5.00001V8.68601L22.6074 11.293C22.7948 11.4805 22.9002 11.7348 22.9002 12C22.9002 12.2652 22.7948 12.5195 22.6074 12.707L20.0004 15.314V19C20.0004 19.2652 19.895 19.5196 19.7075 19.7071C19.5199 19.8946 19.2656 20 19.0004 20H15.3144L12.7074 22.607C12.5198 22.7945 12.2655 22.8998 12.0004 22.8998C11.7352 22.8998 11.4809 22.7945 11.2934 22.607L8.68637 20H5.00037C4.73516 20 4.4808 19.8946 4.29327 19.7071C4.10573 19.5196 4.00037 19.2652 4.00037 19V15.314L1.39337 12.707C1.2059 12.5195 1.10059 12.2652 1.10059 12C1.10059 11.7348 1.2059 11.4805 1.39337 11.293L4.00037 8.68601V5.00001C4.00037 4.73479 4.10573 4.48044 4.29327 4.2929C4.4808 4.10536 4.73516 4.00001 5.00037 4.00001H8.68637ZM12.0004 15C12.796 15 13.5591 14.6839 14.1217 14.1213C14.6843 13.5587 15.0004 12.7957 15.0004 12C15.0004 11.2044 14.6843 10.4413 14.1217 9.87868C13.5591 9.31608 12.796 9.00001 12.0004 9.00001C11.2047 9.00001 10.4417 9.31608 9.87905 9.87868C9.31644 10.4413 9.00037 11.2044 9.00037 12C9.00037 12.7957 9.31644 13.5587 9.87905 14.1213C10.4417 14.6839 11.2047 15 12.0004 15Z"
              fill="#554B95"
            />
          </g>
          <defs>
            <clipPath id="clip0_1_2869">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>

        <p>Resources</p>
      </router-link>

      <router-link to="/profile">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_1_2965)">
            <path
              d="M20 22H4V20C4 18.6739 4.52678 17.4021 5.46447 16.4645C6.40215 15.5268 7.67392 15 9 15H15C16.3261 15 17.5979 15.5268 18.5355 16.4645C19.4732 17.4021 20 18.6739 20 20V22ZM12 13C11.2121 13 10.4319 12.8448 9.7039 12.5433C8.97595 12.2417 8.31451 11.7998 7.75736 11.2426C7.20021 10.6855 6.75825 10.0241 6.45672 9.2961C6.15519 8.56815 6 7.78793 6 7C6 6.21207 6.15519 5.43185 6.45672 4.7039C6.75825 3.97595 7.20021 3.31451 7.75736 2.75736C8.31451 2.20021 8.97595 1.75825 9.7039 1.45672C10.4319 1.15519 11.2121 1 12 1C13.5913 1 15.1174 1.63214 16.2426 2.75736C17.3679 3.88258 18 5.4087 18 7C18 8.5913 17.3679 10.1174 16.2426 11.2426C15.1174 12.3679 13.5913 13 12 13Z"
              fill="#554B95"
            />
          </g>
          <defs>
            <clipPath id="clip0_1_2965">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>

        <p>Profile</p>
      </router-link>
    </div>

    <div class="logout_container" @click="logOutHandler">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_1_2722)">
          <path
            d="M5 2H19C19.2652 2 19.5196 2.10536 19.7071 2.29289C19.8946 2.48043 20 2.73478 20 3V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V3C4 2.73478 4.10536 2.48043 4.29289 2.29289C4.48043 2.10536 4.73478 2 5 2ZM9 11V8L4 12L9 16V13H15V11H9Z"
            fill="#554B95"
          />
        </g>
        <defs>
          <clipPath id="clip0_1_2722">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>

      <p>Logout</p>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {},
  methods: {
    logOutHandler() {
      this.$store.dispatch("clearSignUpDetails");
      window.location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";
.side_menu {
  width: 21.1875em;
  padding: 2.75rem 3.1875rem;
  background-color: #fafafa;
  height: 100vh;
  flex-shrink: 0;

  .signup_body_text_logo {
    display: flex;
    width: 10.3125em;
    height: 3.6875em;
    margin-bottom: 2.8125em;
    justify-content: center;
    align-items: center;

    .signup_body_text_logo_inner {
      width: 100%;
      display: flex;
      height: auto;

      img {
        width: 100%;
        object-fit: contain;
        height: auto;
      }
    }
  }

  .side_menu_links {
    width: 13.5em;
    height: 29.25em;
    flex-shrink: 0;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.375rem;

    .router-link-active {
      width: 14.5em;
      background-color: $black-color;

      svg {
        fill: #fafafa;
        path {
          fill: #fafafa;
        }
        rect {
          fill: #fafafa;
        }
      }
      p {
        color: $lilac-color;
      }
    }
    & a {
      display: flex;
      width: 13.5em;
      height: 3.125em;
      flex-shrink: 0;
      border-radius: 0.3125rem;
      align-items: center;
      text-decoration: none;

      p {
        font-family: "Plus Jakarta Sans";
        font-size: 1.0625rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: rgba(54, 53, 57, 0.65);
      }

      svg {
        margin: 0rem 1.1875rem;
      }
    }
  }
  .logout_container {
    display: flex;
    cursor: pointer;
    width: 13.5em;
    height: 3.125em;
    flex-shrink: 0;
    border-radius: 0.3125rem;
    align-items: center;
    text-decoration: none;

    p {
      font-family: "Plus Jakarta Sans";
      font-size: 1.0625rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: rgba(54, 53, 57, 0.65);
    }
    svg {
      margin: 0rem 1.1875rem;
    }
  }
}
</style>
