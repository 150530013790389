<template>
  <circle-loader v-if="isLoading" />
  <div class="body">
    <div class="gift_card_inner">
      <div class="gift_card_inner_wrapper">
        <div class="gift_card_inner_wrapper_image">
          <img src="../../assets/amazon.png" />
        </div>
        <form @submit.prevent="submitForm">
          <div class="gift_card_inner_wrapper_input">
            <label for="country">Country</label>
            <select id="country" name="country">
              <option value="USA">USA</option>
              <option value="Canada">Canada</option>
              <option value="Mexico">Mexico</option>
              <option value="UK">UK</option>
              <option value="Germany">Germany</option>
            </select>
          </div>

          <div class="gift_card_inner_wrapper_input">
            <label for="amount">Amount to Buy</label>
            <input id="amount" name="amount" placeholder="Amount to Buy" />
          </div>

          <div class="gift_card_inner_wrapper_price">
            <p>Price in Naira</p>
            <p>#500,000</p>
          </div>

          <div v-if="!isLoading" class="button">
            <button class="button_btn">Make Payment</button>
          </div>

          <div v-else class="button">
            <button :disabled="isLoading" class="button_btn">
              <div class="loading-circle"></div>
            </button>
          </div>
        </form>

        <p class="gift_card_inner_wrapper_text">
          Lorem ipsum dolor sit amet consectetur. Libero aliquam commodo pretium
          auctor adipiscing odio mauris proin. Eu lorem vitae eu ut. Adipiscing
          congue pellentesque viverra aliquam magna nunc sit. Quam habitant
          morbi id et suscipit amet.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import CircleLoader from "../../components/loader/CircleLoader.vue";
export default {
  data() {
    return {
      isLoading: false,
    };
  },
  components: {
    CircleLoader,
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";
.body {
  display: flex;
  flex-direction: column;
  width: 100%;

  .gift_card_inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: auto;

    .gift_card_inner_wrapper {
      width: 612px;
      height: 370px;
      padding: 16px 8px 16px 8px;
      gap: 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .gift_card_inner_wrapper_image {
        display: flex;
        flex-direction: column;
        width: 60px;
        align-self: center;
        height: 60px;

        & img {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: auto;
        }
      }
    }

    & form {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 100%;

      .gift_card_inner_wrapper_price {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & p {
          font-size: 14px;
          font-weight: 400;
          line-height: 17.64px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: #2b2b2b;
        }
      }

      .gift_card_inner_wrapper_input {
        display: flex;
        flex-direction: column;
        gap: 8px;

        & label {
          font-size: 14px;
          font-weight: 400;
          line-height: 17.64px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: #2b2b2b;
        }

        & select,
        & input {
          padding: 24px;
          gap: 0px;
          border-radius: 8px;
          border: 0.5px;
          border: 0.5px solid #8f8989;
          outline: none;
          -webkit-appearance: none;
        }
      }
    }

    .gift_card_inner_wrapper_text {
      font-size: 12px;
      font-weight: 400;
      line-height: 15.12px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #706f72;
    }
  }
}
.loading-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid #fff;
  position: relative;
  animation: rotate 1s linear infinite;
}

.loading-circle::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
  transform: translate(-50%, -50%);
  animation: bounce 0.6s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
