import axios from "axios";

const baseUrl = process.env.VUE_APP_BASE_URL;

const brevo_url = process.env.VUE_APP_BREVO_URL;

const KEY = process.env.VUE_APP_BREVO_KEY;

const Authentication = {
  registerUserHandler: (payload) => {
    const response = axios({
      url: `${baseUrl}/api/v1/register`,
      method: "POST",
      data: JSON.stringify({
        email: payload.email,
        refCode: payload.referralCode,
        firstName: payload.firstName,
        lastName: payload.lastName,
        businessName: payload.businessName,
        businessPhoneNumber: payload.businessPhoneNumber,
        businessLocation: payload.businessLocation,
        password: payload.password,
        industry: payload.industry,
        confirmPassword: payload.confirmPassword,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  loginHandler: (payload) => {
    const response = axios({
      url: `${baseUrl}/api/v1/login`,
      method: "POST",
      data: JSON.stringify({
        email: payload.email,
        password: payload.password,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  forgotPasswordHandler: (payload) => {
    const response = axios({
      url: `${baseUrl}/api/v1/forgot-password`,
      method: "POST",
      data: JSON.stringify({
        email: payload.email,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  verifyResetPassword: (payload) => {
    const response = axios({
      url: `${baseUrl}/api/v1/verify?resetToken=${payload.resetToken}&userId=${payload.userId}`,
      method: "POST",
      data: JSON.stringify({
        newPassword: payload.newPassword,
        confirmNewPassword: payload.confirmNewPassword,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  VerifyEmail: (payload) => {
    const response = axios({
      url: `${baseUrl}/api/v1/email-verification`,
      method: "POST",
      data: JSON.stringify({
        code: payload.code,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  generateEmailVerificationCode: (payload) => {
    const response = axios({
      url: `${baseUrl}/api/v1/generate-vcode`,
      method: "POST",
      data: JSON.stringify({
        email: payload.email,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  refreshToken: (payload) => {
    const response = axios({
      url: `${baseUrl}/api/v1/refresh-token`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${payload.refresh_token}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  getUserDetails: (payload) => {
    const res = axios({
      url: `${baseUrl}/api/v1/user`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  verifyUserBusiness: (payload) => {
    const res = axios({
      url: `${baseUrl}/api/v1/verify-business/${payload.RCNumber}`,
      method: "GET",

      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  getRefferalDetails: (payload) => {
    const res = axios({
      url: `${baseUrl}/api/v1/referral-details`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  brevoWelcomeMessage: (payload) => {
    const response = axios({
      url: `${brevo_url}/smtp/email`,
      method: "POST",
      data: JSON.stringify({
        sender: {
          name: "Subomi from Novoinno",
          email: "support@novoinno.com",
        },
        params: { NAME: payload.name },
        to: [{ email: payload.email, name: payload.name }],
        templateId: 1,
      }),
      headers: {
        accept: "application/json",
        "content-type": "application/json",
        "api-key": KEY,
      },
    });

    return response;
  },
};
export default Authentication;
