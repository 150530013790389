<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="giftCard">
    <div class="giftCard_side_menu"><side-menu /></div>

    <router-view></router-view>
  </div>
</template>

<script>
import SideMenu from "../../components/menu/SideMenu.vue";
export default {
  components: {
    SideMenu,
    // Register the component
  },
};
</script>

<style lang="scss" scoped>
.giftCard {
  display: flex;
  height: 100vh;
  overflow: scroll;

  @media only screen and (max-width: 920px) {
    height: auto;
  }

  .giftCard_side_menu {
    @media only screen and (max-width: 920px) {
      display: none;
    }
  }
}
</style>
