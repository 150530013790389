<template>
  <circle-loader v-if="isLoading" />
  <div class="body" v-else>
    <div v-if="!emptyTable" class="gift_cards_wrapper">
      <div class="gift_cards_inner">
        <div class="gift_cards_filter_wrapper">
          <div class="input gift_cards_input">
            <input
              v-model="searchInput"
              placeholder="Search for gift card"
              class="input_field"
            />
          </div>
        </div>
        <div class="btn_wrapper">
          <div class="button">
            <router-link to="/shipments/book-a-shipment">
              <button class="button_btn">Book a Shipment</button>
            </router-link>
          </div>
        </div>
      </div>

      <div class="gift_cards_wrapper_inner">
        <router-link
          :to="{
            name: 'giftCard',
            params: { giftCardId: gifts?.id },
          }"
          class="gift_cards_wrapper_inner_card"
          v-for="(gifts, index) in giftCards"
          :key="index"
        >
          <div>
            <img :src="gifts.image" :alt="gifts.name" />
          </div>
          <p>{{ gifts.name }}</p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import CircleLoader from "../../components/loader/CircleLoader.vue";
import image from "../../assets/amazon.png";

export default {
  data() {
    return {
      isLoading: false,
      searchInput: "",
      emptyTable: false,
      giftCards: [
        {
          name: "Amazon USA",
          image,
          id: 123223,
        },
        {
          name: "Amazon USA",
          image,
          id: 123223,
        },
        {
          name: "Amazon USA",
          image,
          id: 123223,
        },
        {
          name: "Amazon USA",
          image,
          id: 123223,
        },
        {
          name: "Amazon USA",
          image,
          id: 123223,
        },
        {
          name: "Amazon USA",
          image,
          id: 123223,
        },
        {
          name: "Amazon USA",
          image,
          id: 123223,
        },
        {
          name: "Amazon USA",
          image,
          id: 123223,
        },
        {
          name: "Amazon USA",
          image,
          id: 123223,
        },
        {
          name: "Amazon USA",
          image,
          id: 123223,
        },
        {
          name: "Amazon USA",
          image,
          id: 123223,
        },
        {
          name: "Amazon USA",
          image,
          id: 123223,
        },
        {
          name: "Amazon USA",
          image,
          id: 123223,
        },
      ],
    };
  },
  components: {
    CircleLoader,
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";
.body {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.gift_cards {
  width: 100%;

  padding: 2.75rem 5.125em;

  @media only screen and (max-width: 920px) {
    padding: 0rem 0.5em;
  }

  .gift_cards_empty_inner {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 53.3125em;
    flex-shrink: 0;
    border-radius: 1.875rem;
    gap: 2.8125rem;
    justify-content: center;
    border: 1px solid #dedce9;
    background: #fafafa;
    align-items: center;

    @media only screen and (max-width: 1280px) {
      max-width: 770px;
      height: 100vh;
      overflow-y: scroll;
    }

    @media only screen and (max-width: 920px) {
      overflow: visible;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 21.9375em;
      gap: 52px;

      .btn_wrapper {
        display: flex;
        padding: 23px, 248px, 23px, 243px;

        @media only screen and (max-width: 920px) {
          width: 100%;
        }

        .button {
          position: relative;
          display: inline-block;

          @media only screen and (max-width: 920px) {
            width: 100%;
          }
          a {
            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: center;

            @media only screen and (max-width: 920px) {
              width: 100%;
            }

            button {
              max-width: 21.9375em;
              padding: 0.5em 1em;
              white-space: nowrap;
              text-align: center;

              @media only screen and (max-width: 920px) {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
.gift_cards_wrapper {
  width: 100%;
  padding: 2.75rem 5.125em;

  @media only screen and (max-width: 920px) {
    padding: 0rem 0.5em;
  }
  .gift_cards_inner {
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 1;

    @media only screen and (max-width: 920px) {
      width: 100%;
      flex: none;
      flex-direction: column;
    }

    .gift_cards_filter_wrapper {
      display: flex;
      gap: 1.8125rem;

      .gift_cards_select {
        border-radius: 0.625rem;
        width: 10.375em;
        height: 3.125em;
        border: 0.0625rem solid #dedce9;
        background: #fefefe;

        .gift_cards_select_wrapper {
          padding: 5px 0rem;
          width: 100%;
          height: 3.125em;
          display: flex;
          border-radius: 0.625rem;

          select {
            border-radius: 0.375rem;
            width: 100%;
            height: auto;
            background-color: #fefefe;

            color: #363539;
            border: none;
            border: 1px solid transparent;

            font-family: $secondary-font;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            &:focus {
              outline: none;
            }
          }
        }
      }

      .gift_cards_input {
        width: 21.9375em;
        border-radius: 10px;
        border: 1px solid #dedce9;
        background: #fefefe;

        input {
          border-radius: 10px;
        }
      }
    }

    .btn_wrapper {
      display: flex;
      height: 3.125em;

      @media only screen and (max-width: 920px) {
        width: 100%;
      }

      .button {
        @media only screen and (max-width: 920px) {
          width: 100%;
        }
        a {
          text-decoration: none;
          @media only screen and (max-width: 920px) {
            margin-top: 0.75rem;
            width: 100%;
          }

          button {
            width: 10.875em;

            @media only screen and (max-width: 920px) {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .gift_cards_wrapper_inner {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    margin-top: 80px;
    overflow-y: auto;

    .gift_cards_wrapper_inner_card {
      width: 242px;
      height: 156px;
      padding: 24px;
      gap: 56px;
      display: flex;
      align-items: center;
      flex-direction: column;
      border-radius: 8px;
      border: 0.5px;
      border: 0.5px solid #554b95;
      background: #f6f5fb;
      text-decoration: none;

      & div {
        display: flex;
        width: 32px;
        height: 32px;
        gap: 0px;

        & img {
          display: flex;
          width: 100%;
          height: auto;
        }
      }
      & p {
        font-family: Plus Jakarta Sans;
        font-size: 16px;
        font-weight: 500;
        line-height: 20.16px;
        text-align: center;
        text-decoration: none;
        color: #2b2b2b;
      }
    }
  }
}
</style>
